import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Giving Back Local Charity Program | Bartlett & Grippe",
    description:
      "Bartlett & Grippe donates to charities through our Giving Back Program. We pledge to donate a portion of every personal injury case dollar earned.",
    heroH1: "Giving Back Program",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Bartlett Legal Group’s 2021 Charity Selections</h2>
            <p className="mb-8">
              At Bartlett Legal Group, we believe in giving back through
              charity. We represent people whose lives have been forever altered
              by the negligent, reckless, and intentional actions of others. We
              strive to protect the rights of our clients and to give voices to
              the victims of tragedies. In 2015 Attorney Bartlett instituted the
              Giving Back Program. Since starting this program over $231,000 has
              been donated to local charities and community organizations.
              Attorney Frank Bartlett has pledged to donate a portion of every
              dollar earned in the representation of his personal injury clients
              to charitable organizations.
            </p>
            <h2>Giving Victims A Voice – In the Courtroom and the Community</h2>
            <p className="mb-8">
              I felt compelled to start my own personal injury law practice
              after being disheartened by the way insurance companies and
              corporations schemed to abscond from their financial
              responsibilities in the wake of accidents and abuse. I didn’t want
              to save insurance companies millions, I wanted to save people’s
              livelihoods after they’d been destroyed by an irresponsible or
              malicious party.
            </p>
            <p className="mb-8">
              Since 2015, Bartlett Legal Group and I have been committed to our
              mission of “Giving Victims a Voice.” We’ve accomplished this goal
              by seeking justice for our clients in the courtroom and supporting
              many charitable organizations throughout the community. Below,
              please find information about several noteworthy organizations we
              support.
            </p>
            <h2>Colin’s Crew</h2>
            <p className="mb-8">
              <a
                href="http://www.colins-crew.org/colins-story/"
                target="_blank"
                rel="noreferrer"
              >
                Colin’s Crew
              </a>{" "}
              is a local 501(c)3 focused on bringing moments of comfort and joy
              to families affected by childhood cancer through various programs,
              including Meals4Kids, Snacks4Kids, and Fun4Kids. Colin’s Crew was
              originally inspired by Colin Westbrook, who was diagnosed with
              Acute Lymphoblastic Leukemia (ALL) in October 2009, just days
              after his second birthday.
            </p>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                width="500"
                src="../../images/bartlett-legal-group-charity-300x195.png"
                formats={["auto", "webp", "avif"]}
                alt="Photograph of Colin Westbrook of Colin's Crew charity"
              />
            </div>
            <p className="mb-8">
              Bartlett Legal Group is proud to be an annual Platinum Sponsor for
              Colin’s Crew Night of Hope, an evening of dinner, drinks and
              entertainment to raise money for the organization.
            </p>
            <p className="mb-8">
              We’ll be regularly showcasing great causes in the community, like
              Colin’s Crew, on our Facebook and Twitter pages.
            </p>
            <h2>Hope After Loss</h2>
            <p className="mb-8">
              Hope After Loss is a community of women and men who grieve the
              loss of a pregnancy or infant. Through peer support, burial
              assistance, education, and awareness initiatives, we provide
              connections, comfort, and care to those who have experienced the
              loss of a baby.
            </p>
            <p className="mb-8">
              Bartlett Legal Group is proud to be an annual Diamond Sponsor of
              Hope after Loss’ 5k run/walk and Compassionate Care Awards Dinner.
            </p>
            <h2>Kappa Sigma Military Heroes Campaign</h2>
            <p className="mb-8">
              <a
                href="https://kappasigma.org/military-heroes/"
                target="_blank"
                rel="noreferrer"
              >
                Kappa Sigma’s Military Heroes Campaign
              </a>{" "}
              was designed to honor and aid the thousands of wounded military
              veterans returning from active duty. The Military Heroes Campaign
              provides charitable donations to organizations such as the Fisher
              House and other entities. The Fisher House Foundation is a segment
              of the Intrepid Fallen Heroes Fund that provides free housing to
              wounded veterans and their families throughout the duration of
              rehabilitation.
            </p>
            <div className="mb-8 text-center">
              <StaticImage
                quality="100"
                width="500"
                src="../../images/donation-768x773.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Kappa Sigma receiving $10,000 check from Bartlett Legal Group for Military Heroes Campaign"
              />
              <p>
                <strong>
                  Attorney Bartlett Donates to Kappa Sigma’s Military Heroes
                  Campaign
                </strong>
              </p>
            </div>
            <p className="mb-8">
              The Fisher House Foundation is a 501(c)(3) non-profit organization
              that provides a network of 38 comfort homes on the grounds of
              military and VA major medical centers. The houses are 5,000 to
              16,000 square foot units, with up to 21 suites, donated to the
              military and Department of Veterans Affairs. The Foundation
              provides support to families of patients receiving care at the
              nearby medical center. Further information on the work of Fisher
              House Foundation, can be found by calling 888-294-8560.
            </p>
            <h2>
              In addition to the highlighted charities above, as part of
              Attorney Bartlett’s “giving back program” he proudly supports the
              following charities:
            </h2>
            <ul className="list-disc list-outside ml-6">
              <li>American Liver Foundation</li>
              <li>American Lung Association</li>
              <li>Autism Speaks</li>
              <li>Connecticut Military Corvette Club, Inc.</li>
              <li>Cheshire Food Pantry</li>
              <li>Cheshire Lights of Hope</li>
              <li>Children’s Miracle Network</li>
              <li>Dan G. Naimoli Foundation</li>
              <li>Dartmouth-Hitchcock Norris Cotton Cancer Center</li>
              <li>New London Food Pantry</li>
              <li>Sgt. David Coullard Memorial Fund</li>
              <li>Steve Haijte Memorial Fund</li>
              <li>Sunshine Kids</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
